import { lockScroll } from '../helpers'

class AgeConsent {
  constructor(element) {
    this.element = element
    this.buttons = Array.from(this.element.querySelectorAll('button'))

    this.init()
  }

  init() {
    this.buttons.forEach((button, i) => {
      if (i === 0) {
        button.addEventListener('click', this.setCookie.bind(this, 'ageConsent', 'true', 100000))
      } else if (i === 1) {
        button.addEventListener('click', this.setCookie.bind(this, 'ageConsent', 'false', 100000))
      }
    })

    if (document.cookie.includes('ageConsent=true')) {
      this.element.remove()
    } else {
      lockScroll(true)
      this.element.classList.remove('hidden')
    }
  }

  setCookie(cname, cvalue, exdays) {
    const d = new Date()
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
    const expires = 'expires=' + d.toUTCString()
    document.cookie = cname + '=' + cvalue + ';' + expires
    if (cvalue === 'false') {
      window.history.back()
    } else {
      lockScroll(false)
      this.element.remove()
    }
  }
}

// IIFE to invoke component on mount
(function init() {
  const ageConsentEls = document.querySelectorAll('[data-age-consent]')

  ageConsentEls.forEach((el, i, arr) => {
    if (el.getAttribute('bound') !== null) return

    const ageConsent = new AgeConsent(el)

    el.setAttribute('bound', '')

    const id = arr.length > 1 ? `AgeConsent_${i}` : 'AgeConsent'

    self.Decimal.components[id] = { ...ageConsent, async: true }
  })
})()
